import flagus from "../assets/img/flags/us.svg";
import flagspain from "../assets/img/flags/spain.svg";
import flaggermany from "../assets/img/flags/germany.svg";
import flagitaly from "../assets/img/flags/italy.svg";
import flagrussia from "../assets/img/flags/russia.svg";
import flagchina from "../assets/img/flags/china.svg";
import flagfrench from "../assets/img/flags/french.svg";
import flagarabic from "../assets/img/flags/ae.svg";
import flagturkey from "../assets/img/flags/tr.svg";
import flagNl from "../assets/img/flags/nl.svg";
import flagPolish from "../assets/img/flags/pl.svg";
import flagRomanian from "../assets/img/flags/ro.svg";

// const languages = {
//   en: {
//     label: "English",
//     flag: flagus,
//   },
//   nl: {
//     label: "Nederlands",
//     flag: flagNl,
//   },
//   sp: {
//     label: "Spanish",
//     flag: flagspain,
//   },
//   gr: {
//     label: "German",
//     flag: flaggermany,
//   },     
//   fr: {
//     label: "French",
//     flag: flagfrench,
//   }, 
//   it: {
//     label: "Italian",
//     flag: flagitaly,
//   }, 
//   ru: {
//     label: "Russian",
//     flag: flagrussia,
//   }, 
//   ch: {
//     label: "Chinees",
//     flag: flagchina,
//   }, 
//   ar: {
//     label: "Arabic",
//     flag: flagarabic,
//   },  
//   tr: { 
//     label: "Türkçe",
//     flag: flagturkey,
//   },
// }


const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  nl: {
    label: "Nederlands",
    flag: flagNl,
  },  
  sp: {
    label: "Española",
    flag: flagspain,
  },
  gr: {
    label: "Deutsche",
    flag: flaggermany,
  },
  it: {
    label: "Italiana",
    flag: flagitaly,
  },
  rs: {
    label: "Pусский",
    flag: flagrussia,
  },
  cn: {
    label: "中国人",
    flag: flagchina,
  },
  pl: {
    label: "Polski",
    flag: flagPolish,
  },
  fr: {
    label: "Français",
    flag: flagfrench,
  },
  ar: {
    label: "عربي",
    flag: flagarabic,
  },
    tr: { 
    label: "Türkçe",
    flag: flagturkey,
  },
  ro: { 
    label: "Romanesc",
    flag: flagRomanian,
  },
}

export default languages
