import CryptoJS from 'crypto-js';

const SECRET_KEY = 'AuW7N8iNov9TQ==JXU8HHAYKO+YyWaEgxsbGQ==!HaykoRULEZ!JZe+lzkvZEqg5b20IxRxvA==Gb3wkQYUFkaBvSmMOfX94w==';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

export const localStorageSaveEncrypted =  (name: string,data: any)=> {
    
    const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();

    localStorage.setItem(name, encrypted);
}
  
export const localStorageReadEncrypted =  (name: string) => {

    const encrypted = localStorage.getItem(name);

    isDev ? console.log(encrypted) : null;

    if(encrypted == null){
        return "";
    }
    
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);

    isDev ? console.log(decrypted) : null;

    return decrypted;
}

