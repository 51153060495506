import { RouteObject, createBrowserRouter } from 'react-router-dom';
import App from 'App';
import PrivateRoutes from 'api/privateRoutes';

import Error404 from 'pages/error/Error404';
import GeneralDashBoard from 'pages/dasboards/generalBoard';
import CardSignIn from 'pages/pages/authentication/card/SignIn';
import CardSignUp from 'pages/pages/authentication/card/SignUp';
import CardSignUpSuccess from 'pages/pages/authentication/card/SignUpSucces';
import CardForgotPassword from 'pages/pages/authentication/card/ForgotPassword';
import CardSignOut from 'pages/pages/authentication/card/SignOut';
import CardResetPassword from 'pages/pages/authentication/card/ResetPassword';
import CardTwoFA from 'pages/pages/authentication/card/TwoFA';
import Transactions from 'pages/transactions/transactions';

import ErrorPage from 'ErrorPage';

const routes: RouteObject[] = [
    {
      errorElement: <ErrorPage/>,
      // async loader() {
      //   // This will cause render <ErrorPage /> to render
      //   throw new Error('Broken!');
      // },
      element: <App />,
      children: [
        {
          path: '/',
          element: <PrivateRoutes />,
          children: [
            {
              index: true,
              element: <GeneralDashBoard />
            },
            {
              path: 'transactions',
              element: <Transactions />
            },
          ]
        },
        {
            path: '/auth/',
            errorElement: <ErrorPage/>,
            children: [
              {
                path: 'sign-in',
                element: <CardSignIn />
              },
              {
                path: 'sign-up',
                element: <CardSignUp />
              },
              {
                path: 'sign-up-success',
                element: <CardSignUpSuccess />
              },              
              {
                path: 'sign-out',
                element: <CardSignOut />
              },
              {
                path: 'forgot-password',
                element: <CardForgotPassword />
              },
              {
                path: 'reset-password',
                element: <CardResetPassword />
              },
              {
                path: 'otp',
                element: <CardTwoFA />
              }
            ]
          },   
            {
              path: "*",
              element: <Error404 />
            },     
      ]
    }
  ];

  export const router = createBrowserRouter(routes);
  
  export default routes;  