import React, { createContext, useContext, useState, useEffect} from 'react';
import * as Sentry from "@sentry/react";
import axiosInstance from "./apiInterceptor";
import { useTranslation } from "react-i18next";
import {AuthResponsePortal} from '../api/responses/AuthResponsePortal';
import {localStorageSaveEncrypted, localStorageReadEncrypted} from "./encryption";
// import { json } from 'stream/consumers';

interface AuthContextProps {
    isAuthenticated: boolean;
    isNewRegistration: boolean;
    lastErrorMessage: string;
    user : AuthResponsePortal | null;
    token : string,
    signUpUser: (queryParameters: any) => void;
    loginUser: (queryParameters: any) => void;
    logoutUser: () => void;
    verifyUser: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
const appName = process.env.REACT_APP_COOKIE_NAME;

const POST_REGISTER = "/Auth/Register";
const POST_LOGIN = "/Auth/Login";
const POST_OTPVERIFY = "/Auth/Login";

export const AuthProvider: React.FC<{ children: React.ReactNode, isDev:boolean }> = ({ children }) => {

    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [lastErrorMessage, setlastErrorMessage] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isNewRegistration, setIsNewRegistration] = useState(false);
    const [user, setUser] = useState<AuthResponsePortal | null>(null);
    const [token, setToken] = useState("");

    useEffect(() => {
        const getUser = async () => {
       
            const tokenData = localStorageReadEncrypted(appName + "authToken");
            setToken(tokenData)

            const userData = localStorageReadEncrypted(appName + "authUser");
         
            isDev ? console.log(userData) : null;

            if (typeof userData === "string") {
                if(userData.length != 0){
                    
                    const usr = JSON.parse(userData) as AuthResponsePortal;
                    
                    isDev ? console.log(usr.companyName) : null;

                    setUser(usr);
                    setIsAuthenticated(true);

                }else{
                    
                    setUser(null);
                    setIsAuthenticated(false);

                }
            }else{
                setUser(null);
                setIsAuthenticated(false);
            }
        };
    
        getUser();
      }, []);

    const signUpUser = async (queryParameters: any) => {
    
        await axiosInstance.post(POST_REGISTER, queryParameters) // Replace with your API endpoint
        .then(response => {
            
            isDev ? console.log(response.data) : null;
            
            if(response.data.result === true){
                setIsNewRegistration(true);
            }

            return response;
        })
        .catch(error => {
            isDev ? console.log(error): null;
            isDev ? console.log(error?.response) : null;
            isDev ? console.log(error?.response?.status) : null;

            if (error?.response?.status === 400) {
                setlastErrorMessage(t(error.response.data.errorMessage));
            }else{
                Sentry.captureException(error);
                setlastErrorMessage(t("AuthProvider_Alert_ConnectionFailed"));
            }

            isDev ? console.log(lastErrorMessage) : null;

            return error.response;
        });

    };

    const loginUser = async (queryParameters: any) => {
     
        await axiosInstance.post(POST_LOGIN, queryParameters)
        .then( async response => {
            
            isDev ? console.log(response) : null;
            isDev ? console.log(response.data) : null;
            
            var resp = response.data as AuthResponsePortal;

            isDev ? console.log('Response Data', resp) : null;
            isDev ? console.log(JSON.stringify(response.data)) : null;

            //Do not store the resp object but only the response.data!
            localStorageSaveEncrypted(appName + "authUser", JSON.stringify(response.data));
            localStorageSaveEncrypted(appName + "authToken", resp.token);
            
            setIsAuthenticated(true);

            return isAuthenticated;
        })
        .catch(error => {

            let errorMessage = '';

            isDev ? console.log(error): null;
            isDev ? console.log(error?.response) : null;
            isDev ? console.log(error?.response?.status) : null;

            if (error?.response?.status === 400) {
                isDev ? console.log(error.response.data.errorMessage) : null;
               
                setlastErrorMessage(t(error.response.data.errorMessage));

            }else{
                Sentry.captureException(error);
                isDev ? console.log("Connection Error!") : null;
                setlastErrorMessage(t("AuthProvider_Alert_ConnectionFailed"));
            }

            isDev ? console.log(errorMessage) : null;

            return isAuthenticated;
        });
      
    };

    const logoutUser = async () => {
        setIsAuthenticated(false);
        localStorage.removeItem(appName + "authUser");
        localStorage.removeItem(appName + "authToken");
    };

    const verifyUser = async () => {

        if (user !== null) {
            user.otpVerified = true;

            let userData = localStorageReadEncrypted(appName + "authUser");

            var usr = JSON.parse(userData);

            usr["otpVerified"] = true;

            isDev ? console.log(usr) : null;

            localStorageSaveEncrypted(appName + "authUser", JSON.stringify(usr));

            setUser(user);

            isDev ? console.log(user) : null;
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isNewRegistration, lastErrorMessage, user, token, 
                        loginUser, logoutUser , signUpUser, verifyUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};