import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../api/userContext';
import Button from 'components/base/Button';
// import AuthSocialButtons from 'components/common/AuthSocialButtons';
import validateEmail from 'email-validator-util';
import validator from 'validator';
import { Col, Form, Row, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons'
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthRequestPortal} from '../../../api/requests/AuthRequestPortal';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {

  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  
  const { t } = useTranslation();
  
  const [validated, setValidated] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const { loginUser , isAuthenticated, lastErrorMessage } = useAuth();

  const closeToast = () => {
    setShowErrorToast(false);
    setErrorText('');
    setLoading(false);
  };

  useEffect(() => {
    isDev ? console.log('LastErrorMessage', lastErrorMessage) : null;
    
      if(lastErrorMessage !== ""){
        setLoading(false);
        setShowErrorToast(true);  
        setErrorText(lastErrorMessage);
      }
 
  },[lastErrorMessage])

  useEffect(() => {
    isDev ? console.log('isAuthenticated', isAuthenticated) : null;

    if(isAuthenticated === true){
      window.location.replace('/');     //Make this smarter and use redirect to param in login
    }
 
  }, [isAuthenticated])

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setLoading(true);
    //e.stopPropagation();

    const form = e.currentTarget;

    const formElements = form.elements as typeof form.elements & {
      email : {value: HTMLInputElement},
      password : {value: HTMLInputElement},
    }

    isDev ? console.log(formElements.email.value) : null;
    isDev ? console.log(formElements.password.value) : null;

    var isFormValid = form.checkValidity();
    isDev ? console.log(isFormValid) : null;

    if (isFormValid === false) {
      setValidated(true);
      setLoading(false);
      return;
    }

     //validate email seperately..
     const isEmailValid = validateEmail(formElements.email.value);
     isDev ? console.log(isEmailValid) : null;
 
     if(!isEmailValid){
       setErrorText(t("SignUpForm_Alert_EnterValidEmail"));
       setShowErrorToast(true);
       setLoading(false);
       return;
     }

     if (validator.isStrongPassword(formElements.password.value, { 
      minLength: 6, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 
      })) { 
          // setErrorMessage('Is Strong Password') 
      } else { 
        setErrorText(t("SignUpForm_Alert_EnterStrongPassword"));
        setShowErrorToast(true);
        setLoading(false);
        return;
      } 

      var authRequest = new AuthRequestPortal();
      authRequest.doNotSendOtp = false;
      authRequest.email = formElements.email.value;
      authRequest.password = formElements.password.value;

      setTimeout( async () => {
    
        await loginUser(authRequest);
        setLoading(false);
        setValidated(true);
       

      }, 100)
      
      return;

  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">{t("SignInForm_SignInHeader")}</h3>
        <p className="text-body-tertiary">{t("SignInForm_SignInHeaderSlug")}</p>
      </div>
      <div className="position-fixed top-0 end-0 p-3">
        <Toast style={{backgroundColor : '#E5EBF7', border: '6px solid #F0F2F6'}} onClose={closeToast} show={showErrorToast} delay={5000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto"><FontAwesomeIcon className='fa-shake' color='red' size="xl" icon={faCircleExclamation} /> Ooops..</strong>
            <small>IPAY</small>
            <Button className="ms-2 p-0 " onClick={() => setShowErrorToast(false)} >
              {/* <UilTimes className="fs-7"/> */}
            </Button>
          </Toast.Header>
          <Toast.Body style={{fontSize:'17px', color:'#340404'}}>{errorText}</Toast.Body>
        </Toast>
      </div>        
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">{t("SignInForm_EmailAddress")}</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder={t("SignInForm_EmailAddressPlaceHolder")} required
          />
          <Form.Control.Feedback type="invalid">
             {t("SignInForm_EmailAddressFeedBack")}
          </Form.Control.Feedback>  
          <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">{t("SignInForm_Password")}</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder={t("SignInForm_Password")} required
          />
           <Form.Control.Feedback type="invalid">
             {t("SignInForm_PasswordConfirmFeedBack")}
          </Form.Control.Feedback>  
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
            {t("SignInForm_RememberMe")}
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/auth/forgot-password`}
            className="fs-9 fw-semibold"
          >
            {t("SignInForm_ForgotPassword")}
          </Link>
        </Col>
      </Row>
      <Button variant="primary" type="submit" loading={loading} loadingPosition="end" className="w-100 mb-3" >
      {t("SignInForm_ButtonLogin")}
      </Button>
      </Form>
      <div className="text-center">
        <Link reloadDocument={true}
          to={`/auth/sign-up`}
          className="fs-9 fw-bold"
        >
          {t("SignInForm_ButtonCreateNewAccount")}
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
