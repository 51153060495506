import QueryParameters from 'api/requests/queryParameters';
import { TransactionsPortalDto } from 'api/responses/TransactionsPortalDto';
import React, { useEffect, useMemo, useState , useRef} from 'react';
import * as Sentry from "@sentry/react";
import axiosInstance from "../../api/apiInterceptor";
import {localStorageReadEncrypted } from "../../api/encryption"

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
const appName = process.env.REACT_APP_COOKIE_NAME;

// export async function fetchTransactions(queryParameters: QueryParameters): Promise<TransactionsPortalDto[]> {

export async function fetchTransactions(queryParameters: QueryParameters): Promise<any> {

    return axiosInstance.post('portal/transactions', queryParameters, {headers: {"Accept-Type":'application/json.'}})
        .then(async (response: { data: any }) => {

            isDev ? console.log(response.data) : null;

            isDev ? console.log(response) : null;

            return response.data;
        })
        .catch((error: any) => {
            let errorMessage = '';
            isDev ? console.log(error) : null;
            isDev ? console.log(error?.response) : null;
            isDev ? console.log(error?.response?.status) : null;

            if (error?.response?.status === 400) {
                isDev ? console.log(error.response.data.errorMessage) : null;
            } else {
                Sentry.captureException(error);
                isDev ? console.log("Connection Error!") : null;
            }

            isDev ? console.log(errorMessage) : null;
            return [];
        });
}
  
export async function exportTransactions(queryParameters: QueryParameters): Promise<any> {
        
        const token = localStorageReadEncrypted(appName + "authToken");

        isDev ? console.log(queryParameters) : null;
   
        // return axiosInstance.post('portal/ExportTransactions', queryParameters, { responseType: 'blob' })

        return axiosInstance.post('portal/ExportTransactions', queryParameters, { headers: {"Accept-Type":'application/json.'}, responseType: 'blob'})
        .then(async (response) => {

            const contentDisposition = response.headers["content-disposition"];
            isDev ? console.log(response.headers) : null;
            isDev ? console.log(response) : null;
            isDev ? console.log(contentDisposition) : null;

            let fileName = "ipayTransactions.xls";

            const filenameRegexExtended = /filename\*=UTF-8''(.+)/;
            const filenameRegexBasic = /filename=(.+?)(;|$)/;
      
            const matchExtended = contentDisposition.match(filenameRegexExtended);
            const matchBasic = contentDisposition.match(filenameRegexBasic);
      
            if (matchExtended) {
              // Decode URI if using filename*
              fileName = decodeURIComponent(matchExtended[1]);
            } else if (matchBasic) {
              // Fallback to standard filename
              fileName = matchBasic[1].replace(/"/g, "").trim();
            }

            isDev ? console.log(fileName) : null;

            var fileURL = window.URL.createObjectURL(
                new Blob([response.data])
            );
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);

            fileLink.click();

            // const url = window.URL.createObjectURL(new Blob(response.data));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'report.csv'); // Provide the filename for download
            // document.body.appendChild(link);
            // link.click();

            // // Clean up by removing the link and revoking the URL
            // link.parentNode!.removeChild(link);
            // window.URL.revokeObjectURL(url);

            return response.data;
        })
        .catch((error: any) => {
            let errorMessage = '';
            isDev ? console.log(error) : null;
            isDev ? console.log(error?.response) : null;
            isDev ? console.log(error?.response?.status) : null;

            if (error?.response?.status === 400) {
                isDev ? console.log(error.response.data.errorMessage) : null;
            } else {
                Sentry.captureException(error);
                isDev ? console.log("Connection Error!") : null;
            }

            isDev ? console.log(errorMessage) : null;
            return [];
        });


        // return axiosInstance.post('portal/ExportTransactions', queryParameters),
        //     .then(async (response: { data: any }) => {

        //         isDev ? console.log(response.data) : null;
        //         isDev ? console.log(response) : null;

        //         return response.data;
        //     })
        //     .catch((error: any) => {
        //         let errorMessage = '';
        //         isDev ? console.log(error) : null;
        //         isDev ? console.log(error?.response) : null;
        //         isDev ? console.log(error?.response?.status) : null;

        //         if (error?.response?.status === 400) {
        //             isDev ? console.log(error.response.data.errorMessage) : null;
        //         } else {
        //             Sentry.captureException(error);
        //             isDev ? console.log("Connection Error!") : null;
        //         }

        //         isDev ? console.log(errorMessage) : null;
        //         return [];
        //     });
}

export async function fetchCustomerList(): Promise<any> {

    return axiosInstance.get('portal/getsharedcustomers', {headers: {"Accept-Type":'application/json.'}})
        .then(async (response: { data: any }) => {

            isDev ? console.log(response.data) : null;
            isDev ? console.log(response) : null;

            return response.data;
        })
        .catch((error: any) => {
            let errorMessage = '';
            isDev ? console.log(error) : null;
            isDev ? console.log(error?.response) : null;
            isDev ? console.log(error?.response?.status) : null;

            if (error?.response?.status === 400) {
                isDev ? console.log(error.response.data.errorMessage) : null;
            } else {
                Sentry.captureException(error);
                isDev ? console.log("Connection Error!") : null;
            }

            isDev ? console.log(errorMessage) : null;
            return [];
        });
}