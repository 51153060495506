import SignUpForm from 'components/modules/auth/SignUpSuccessForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const SignUpSuccess = () => {
  return (
    <AuthCardLayout className="card-sign-up">
      <SignUpForm layout="card" />
    </AuthCardLayout>
  );
};

export default SignUpSuccess;
