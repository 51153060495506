import Button from 'components/base/Button';
// import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row , Toast} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../api/userContext';
import ReactSelect from 'components/base/ReactSelect';
import validateEmail from 'email-validator-util';
import validator from 'validator';
import RegistrationRequest from '../../../api/requests/RegistrationRequest'
import { getCountryDataList } from 'countries-list'
import { Navigate, useLocation} from 'react-router-dom';
import { useTranslation } from "react-i18next";
// import {faHome} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons'

// import { FormEvent } from 'react';
// import LanguageDropdown from '../../../components/common/languageDropdown';

const SignUpForm = ({ layout}  : { layout: 'simple' | 'card' | 'split' }) => {
  
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  
  const { t } = useTranslation();
  // const location = useLocation();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [validated, setValidated] = useState(false);
  const [listOfCountries, setListOfCountries] = useState<{ key: string; value: string; label:string }[]>([]);
  const [selectedCountry, setSelectedCountry] = useState([
    {
      key: "NL",
      value: "NL",
      label:"Netherlands"
    },
  ]);

  const [loading, setLoading] = useState(false);
  const { signUpUser , isNewRegistration, lastErrorMessage } = useAuth();

  useEffect(() => {

    var countries = getCountryDataList();

    isDev ? console.log(countries) : null;

    const cnt: {key: string; value: string; label:string}[] = [];
    
    countries.forEach((value) => {

      //For now we cap the countries to these because we don't support carriers in other countrues
      if(value.iso2 == "NL" || value.iso2 =="BE" || value.iso2 == "FR" || value.iso2 == "DE"){
        cnt.push({
          key: value.iso2,
          value: value.iso2,
          label: value.name
        });
      }
    
    });
  
    setListOfCountries(cnt);

    isDev ? console.log(cnt) : null;

  }, []);

  const handleCountryChanged = (value : any) => {
    setSelectedCountry(value);
    isDev ? console.log(value) : null;
  };

  useEffect(() => {
    isDev ? console.log('LastErrorMessage', lastErrorMessage) : null;
 
    if(lastErrorMessage !== ""){

      setShowErrorToast(true);
      setValidated(true);
      setLoading(false);
      setErrorText(lastErrorMessage);
    }
 
  }, [lastErrorMessage])

  useEffect(() => {
    isDev ? console.log('IsNewRegistration', isNewRegistration) : null;

    if(isNewRegistration === true){
      // //redirect to signup success!
      // return () => { <Navigate to="auth/sign-up-success" replace state={{ from: location }} />};
      const timeout = setTimeout(() => {
        window.location.replace('sign-up-success');
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
 
  }, [isNewRegistration])

  const closeToast = () => {
    setShowErrorToast(false);
    setErrorText('');
    setLoading(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
 
    setLoading(true);

    const form = e.currentTarget;

    const formElements = form.elements as typeof form.elements & {
      firstName: {value: HTMLInputElement},
      lastName : {value: HTMLInputElement},
      companyName : {value: HTMLInputElement},
      mobile : {value: HTMLInputElement},
      email : {value: HTMLInputElement},
      password : {value: HTMLInputElement},
      confirmPassword : {value: HTMLInputElement},
      termsService : {value: HTMLInputElement},
    }

    isDev ? console.log(formElements.firstName.value) : null;
    isDev ? console.log(formElements.lastName.value) : null;
    isDev ? console.log(formElements.companyName.value) : null;
    isDev ? console.log(formElements.mobile.value) : null;
    isDev ? console.log(formElements.email.value) : null;
    isDev ? console.log(formElements.password.value) : null;
    isDev ? console.log(formElements.confirmPassword.value) : null;
    isDev ? console.log(formElements.termsService.checked) : null;

    var isFormValid = form.checkValidity();
    isDev ? console.log(isFormValid) : null;

    if (isFormValid === false) {
      setValidated(true);
      setLoading(false);

      return;
    }

    //validate email seperately..
    const isEmailValid = validateEmail(formElements.email.value);
    isDev ? console.log(isEmailValid) : null;

    if(!isEmailValid){
      setErrorText(t("SignUpForm_Alert_EnterValidEmail"));
      setShowErrorToast(true);
      setLoading(false);
      return;
    }

    if(formElements.mobile.value.indexOf("+") === -1){
      setErrorText(t("SignUpForm_Alert_EnterValidMobile"));
      setShowErrorToast(true);
      setLoading(false);
      return;
    }

    if(formElements.password.value !== formElements.confirmPassword.value){
      setErrorText(t("SignUpForm_Alert_PasswordsDoNotMatch"));
      setShowErrorToast(true);
      setLoading(false);
      return;
    }

    if (validator.isStrongPassword(formElements.password.value, { 
      minLength: 6, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 
      })) { 
          // setErrorMessage('Is Strong Password') 
      } else { 
        setErrorText(t("SignUpForm_Alert_EnterStrongPassword"));
        setShowErrorToast(true);
        setLoading(false);
        return;
      } 

      if(formElements.termsService.checked === false){
        setErrorText(t("SignUpForm_Alert_AcceptPolicies"));
        setShowErrorToast(true);
        setLoading(false);
        return;
      }

    isDev ? console.log("Valid Form!") : null;
    
    let req = new RegistrationRequest();
    req.id = "-1";
    req.firstName = formElements.firstName.value;
    req.lastName = formElements.lastName.value;
    req.companyName = formElements.companyName.value;
    req.phoneNumber = formElements.mobile.value;
    req.email = formElements.email.value;
    req.password = formElements.password.value;
    
    isDev ? console.log(req) : null;
    
    setTimeout( async () => {
    
      await signUpUser(req);

    }, 100)
    
    return;
  };

  return (
    <>
      <div className="text-center mb-4">
        <h3 className="text-body-highlight">{t("SignUpForm_SignUp")}</h3>
        <p className="text-body-tertiary">{t("SignUpForm_SignUpSlug")}</p>
      </div>
      <div className="position-fixed top-0 end-0 p-3">
        <Toast style={{backgroundColor : '#E5EBF7', border: '6px solid #F0F2F6'}} onClose={closeToast} show={showErrorToast} delay={5000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto"><FontAwesomeIcon className='fa-shake' color='red' size="xl" icon={faCircleExclamation} /> Ooops..</strong>
            <small>IPAY</small>
            <Button className="ms-2 p-0 " onClick={() => setShowErrorToast(false)} >
              {/* <UilTimes className="fs-7"/> */}
            </Button>
          </Toast.Header>
          <Toast.Body style={{fontSize:'17px', color:'#340404'}}>{errorText}</Toast.Body>
        </Toast>
      </div>       
      {/* <Row className="g-3 mb-3">
        <LanguageDropdown />
       </Row>       */}

      {/* <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <span className="fw-semibold text-body-secondary fs-9">Select your language:</span>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
              <LanguageDropdown />
          </Col>
        </Row>   */}
      {/*<AuthSocialButtons title="Sign up" />*/}
      {/*<div className="position-relative mt-4">*/}
      {/*  <hr className="bg-body-secondary" />*/}
      {/*  <div className="divider-content-center">or use email</div>*/}
      {/*</div>*/}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">{t("SignUpForm_FirstName")}</Form.Label>
              <Form.Control id="firstName" type="text" placeholder={t("SignUpForm_FirstName")} required/>
              <Form.Control.Feedback type="invalid">
              {t("SignUpForm_FirstNameFeedBack")}
              </Form.Control.Feedback>              
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="Last name">
              {t("SignUpForm_LastName")}
              </Form.Label>
              <Form.Control
                id="lastName"
                type="text"
                placeholder={t("SignUpForm_LastName")}
                required
              />
              <Form.Control.Feedback type="invalid">
              {t("SignUpForm_LastNameFeedBack")}
              </Form.Control.Feedback>                
            </Form.Group>
          </Col>
        </Row>        
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">{t("SignUpForm_CompanyName")}</Form.Label>
          <Form.Control
            id="companyName"
            type="text"
            placeholder={t("SignUpForm_CompanyName")}
            required
          />
          <Form.Control.Feedback type="invalid">
             {t("SignUpForm_CompanyNameFeedBack")}
          </Form.Control.Feedback>            
        </Form.Group>       
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="mobile">{t("SignUpForm_MobileNumber")}</Form.Label>
          <Form.Control
            id="mobile"
            type="text"
            placeholder={t("SignUpForm_MobileNumberPlaceHolder")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {t("SignUpForm_MobileNumberFeedBack")}
          </Form.Control.Feedback>              
        </Form.Group>   
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="country">{t("SignUpForm_ListOfCountriesWeSupport")}</Form.Label>
          <ReactSelect
            id="country"
            value={selectedCountry}
            options={listOfCountries}
            handleChanged={handleCountryChanged}
            placeholder="Select your country..."
            />
        </Form.Group>                 
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">{t("SignUpForm_EmailAddress")}</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder={t("SignUpForm_EmailAddressPlaceHolder")}
            required
          />
          <Form.Control.Feedback type="invalid">
          {t("SignUpForm_EmailAddressFeedBack")}
          </Form.Control.Feedback>            
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">{t("SignUpForm_Password")}</Form.Label>
              <Form.Control id="password" type="text" placeholder={t("SignUpForm_Password")} required/>
              <Form.Control.Feedback type="invalid">
              {t("SignUpForm_PasswordFeedBack")}
              </Form.Control.Feedback>                  
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
              {t("SignUpForm_PasswordConfirm")}
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="text"
                placeholder={t("SignUpForm_PasswordConfirm")}
                required
              />
              <Form.Control.Feedback type="invalid">
              {t("SignUpForm_PasswordConfirmFeedBack")}
              </Form.Control.Feedback>               
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            {t("SignUpForm_TermsLeading")} <Link to="#!">{t("SignUpForm_Terms")} </Link>and{' '}
            <Link to="#!">{t("SignUpForm_PrivacyPolicy")}</Link>
          </Form.Check.Label>
         
        </Form.Check>
        <Button variant="primary"  type="submit" className="w-100 mb-3" loading={loading} loadingPosition="end">
          {t("SignUpForm_Button_SignUp")}
        </Button>
        {/* <Button variant="primary" className="w-100 mb-3" loading={loading} onClick={signUp} loadingPosition="end">
              Sign up
        </Button> */}
        <div className="text-center">
          <Link to={`/auth/sign-in`} className="fs-9 fw-bold">
            {t("SignUpForm_Button_Login")}
          </Link>
        </div>
      </Form>

    </>
  );
};

export default SignUpForm;
