import AppProvider from 'providers/AppProvider';
import React from 'react';
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
// import { router } from 'Routes';
import { router } from 'appRoutes';
import { AuthProvider } from './api/userContext';
import './i18n';

const isDev = process.env.REACT_APP_NODE_ENV === 'development'
// const store = configureStore({ reducer: rootReducer, devTools: isDev });

Sentry.init({
  dsn: "https://018e0c0a304d4ad3f199fb1804abf7bf@o4508259609608192.ingest.de.sentry.io/4508259611443280",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


//const authUrl = isDev ? process.env.REACT_APP_DEV_AUTH_URL : process.env.REACT_APP_PROD_AUTH_URL
//const container = document.getElementById('root');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <SettingsPanelProvider>
          <BreakpointsProvider>
            <AuthProvider isDev={isDev}>
              <RouterProvider router={router} />
            </AuthProvider>            
          </BreakpointsProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </React.StrictMode>
);

// root.render(
//   <React.StrictMode>
//     <AppProvider>
//    {     <BreakpointsProvider>
//           <RouterProvider router={router} />
//         </BreakpointsProvider>}
//     </AppProvider>
//   </React.StrictMode>
// );

// root.render(
//   <React.StrictMode>
//     <AppProvider>
//       <SettingsPanelProvider>
//         <ChatWidgetProvider>
//           <BreakpointsProvider>
//             <RouterProvider router={router} />
//           </BreakpointsProvider>
//         </ChatWidgetProvider>
//       </SettingsPanelProvider>
//     </AppProvider>
//   </React.StrictMode>
// );
