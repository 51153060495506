import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationIT from "./locales/it.json";
import translationRS from "./locales/ru.json";
import translationENG from "./locales/en.json";
import translationCN from "./locales/ch.json";
import translationFR from "./locales/fr.json";
import translationAR from "./locales/ar.json";
import translationTr from "./locales/tr.json";
import translationNL from "./locales/nl.json";
import translationES from "./locales/sp.json";
import translationDE from "./locales/gr.json";
import translationPolish from "./locales/pl.json";
import translationRomanian from "./locales/ro.json";

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

const resources = {
    en: {
      translation: translationENG,
    },
    nl: {
      translation: translationNL,
    },    
    sp: {
      translation: translationES,
    },   
    gr: {
      translation: translationDE,
    },        
    it: {
      translation: translationIT,
    },    
    rs: {
      translation: translationRS,
    },  
    cn: {
      translation: translationCN,
    }, 
    pl: {
      translation: translationPolish,
    },            
    fr: {
      translation: translationFR,
    },    
    ar: {
      translation: translationAR,
    },    
    tr: {
      translation: translationTr,
    },
    ro: {
      translation: translationRomanian,
    },
  };

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: isDev,
    lng: localStorage.getItem("I18N_LANGUAGE") || "nl",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
