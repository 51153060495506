import EcomTopCouponsChart from 'components/charts/e-charts/EcomTopCouponsChart';
import { Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const CancellationPercentage = () => {

  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  const { t } = useTranslation();

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">{t('GeneralDashBoard_CancellationPercentageCard_Title')}</h5>
            <h6 className="text-body-tertiary">{t('GeneralDashBoard_CancellationPercentageCard_Last7Days')}</h6>
          </div>
        </div>
        <div className="pb-4 pt-3">
          <EcomTopCouponsChart />
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_CancellationPercentageCard_BeforeShipment')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">1%</h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-lighter me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_CancellationPercentageCard_ByCarrier')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">18%</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-info-dark me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_CancellationPercentageCard_AtTheDoor')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">10%</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CancellationPercentage;
