import EcomPayingVsNonPayingChart from 'components/charts/e-charts/EcomPayingVsNonPayingChart';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const PayingVsNonPayingCard = () => {

    const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
    const { t } = useTranslation();

  return (
    <Card className="h-100">
      <Card.Body className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">{t('GeneralDashBoard_PayingVsNonPayingCard_Title')}</h5>
            <h6 className="text-body-tertiary">{t('GeneralDashBoard_PayingVsNonPayingCard_Last7Days')}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 flex-1">
          <EcomPayingVsNonPayingChart />
        </div>
        <div className="mt-3">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_PayingVsNonPayingCard_PayingCustomer')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">30%</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-primary-subtle me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_PayingVsNonPayingCard_NonPayingCustomer')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">70%</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PayingVsNonPayingCard;
