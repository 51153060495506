import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import Button from 'components/base/Button';
import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../api/userContext';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const totalInputLength = 6;

const TwoFAForm = ({ layout }: { layout?: 'simple' | 'card' | 'split' }) => {

  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  let navigate = useNavigate(); 

  const { t } = useTranslation();

  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [otp, setOtp] = useState('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { user, token , logoutUser, verifyUser} = useAuth();
  const [otpVerifyCount, setOtpVerifyCount] = useState(0);

  const gotoLoginPage = () =>{ 
    let path = `/auth/sign-in`; 
    navigate(path);
  }

  const gotoDashboard = () =>{ 
    let path = `/`; 
    navigate(path);
  }

  const verifyOtp = async () =>{ 

    let tryCount = otpVerifyCount;
    tryCount++;

    if(otp === user?.otp){
      isDev ? console.log("otpolk") : null;
      await verifyUser();
      await gotoDashboard();
    }

    isDev ? console.log(otp) : null;
    isDev ? console.log(tryCount) : null;

    if(tryCount > 4){
      await logoutUser() 
      await gotoLoginPage();

      return;
    }

    setOtpVerifyCount(otpVerifyCount);
  }


  useEffect(() => {
      isDev ? console.log(token) : null;
      if(user != null ){
        //Do not allow to be on this page if OTP is already verified...
        isDev ? console.log(user) : null;

        if(user.otpVerified === true){
          isDev ? console.log("REDIRECT") : null;
          window.location.href = '/';
        }
      }else{
        isDev ? console.log("REDIRECT") : null;
        window.location.href = '/auth/sign-in';
      }
  },[user])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = e.target;

    if (value) {
      [...value].slice(0, totalInputLength).forEach((char, charIndex) => {
        if (inputRefs.current && inputRefs.current[index + charIndex]) {
          inputRefs.current[index + charIndex]!.value = char;
          inputRefs.current[index + charIndex + 1]?.focus();
        }
      });
    } else {
      inputRefs.current[index]!.value = '';
      inputRefs.current[index - 1]?.focus();
    }

    const updatedOtp = inputRefs.current.reduce(
      (acc, input) => acc + (input?.value || ''),
      ''
    );
    setOtp(updatedOtp);
  };

  return (
    <div>
      <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
        <div
          className={classNames('text-center', {
            'mb-6': !(layout === 'split')
          })}
        >
          <h4 className="text-body-highlight">Enter the verification code</h4>
          <p className="text-body-tertiary mb-0">
            An email containing a 6-digit verification code has been sent to the
            email address - <span className="text-primary">{user?.email}</span> 
          </p>
          {/* <p className="fs-10 mb-5">
            Don’t have access?
            <Link to="#!"> Use another method</Link>
          </p> */}
          <p></p>
          <div className="verification-form">
            <div className="d-flex align-items-center gap-2 mb-3">
              {Array(totalInputLength)
                .fill('')
                .map((_, index) => (
                  <React.Fragment key={index}>
                    <Form.Control
                      ref={(el: HTMLInputElement) => {
                        inputRefs.current[index] = el;
                      }}
                      className="px-2 text-center"
                      type="number"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, index)
                      }
                    />
                    {index === 2 && <span>-</span>}
                  </React.Fragment>
                ))}
            </div>
            {/* <Form.Check type="checkbox" className="text-start mb-4">
              <Form.Check.Input
                type="checkbox"
                name="2fa-checkbox"
                id="2fa-checkbox"
              />
              <Form.Check.Label
                className="fs-8 fw-medium"
                htmlFor="2fa-checkbox"
              >
                Don’t ask again on this device
              </Form.Check.Label>
            </Form.Check> */}
            <Button
              variant="primary"
              className="w-100 mb-5"
              onClick={ async () => {
                await verifyOtp();
            }}
              disabled={otp.length < totalInputLength}
            >
              Verify
            </Button>
            <Link to="#"
                   onClick={ async () => {
                      await logoutUser();
                      await gotoLoginPage();
                  }}
                  className="fs-9">
                  Didn’t receive the code? Please login again and get new code...
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFAForm;
