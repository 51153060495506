import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import logoDark from 'assets/img/logos/ipay-logo-small.png';
import logo from 'assets/img/logos/ipayLogo.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

const NavbarBrand = () => {

  const {
    config: { navbarTopShape, navbarPosition, theme }
  } = useAppContext();

  const { breakpoints } = useBreakpoints();

  isDev ? console.log('NavBrandTheme', theme) : null;

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to="/"
          className={classNames({
            'me-1 me-sm-3':
              navbarTopShape === 'slim' || navbarPosition === 'horizontal'
          })}
        >
            <div className="d-flex align-items-center">
              <img src={theme === 'light' ? logo : logoDark} alt="IPAY" width={130} />
              {/*<p className="logo-text ms-1 d-none d-sm-block">IPAY</p>*/}
            </div>
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
