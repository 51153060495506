import axios from "axios";
import {localStorageReadEncrypted, localStorageSaveEncrypted} from "./encryption"

const POST_REFRESHTOKEN = "/Auth/RefreshToken";

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
const appName = process.env.REACT_APP_COOKIE_NAME;

const ApiUrl = `${isDev ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL}`;
const axiosInstance = axios.create({baseURL: ApiUrl});

const token = localStorageReadEncrypted(appName + "authToken");

isDev ? console.log(token) : null;

axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
// delete axios.defaults.headers.common['Accept-Language'];

  axiosInstance.interceptors.request.use(
    config => {
        const token = localStorageReadEncrypted(appName + "authToken");

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {

      isDev ? console.log(error) : null;

         // Any status codes that falls outside the range of 2xx cause this function to trigger
         var url = error.config.url;
        //  if(error.code == "ERR_BAD_REQUEST"){
        //   if(url.includes("RefreshToken")){
        //     localStorage.removeItem("authUser");
        //     localStorage.removeItem("authToken");
        //     window.location.href = "/loginotp";
        //   }
        //  }

        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const response = await refreshAccessToken();
                const {data} = response;

                // setUser(data);
            } catch (e) {
              // alert('error refreshToken!')
              isDev ? console.log(e) : null;
              localStorage.removeItem(appName + "authUser");
              localStorage.removeItem(appName + "authToken");
              window.location.href = "/auth/sign-in";
            }

            return axiosInstance(originalRequest);
        }

        return Promise.reject(error);
    });

     const refreshAccessToken = async () => {

        var loggedInUser = await localStorageReadEncrypted(appName + "authUser");

        var loginData = null;

        if (typeof loggedInUser === "string") {
          loginData = await JSON.parse(loggedInUser);
         }
      
        isDev ? console.log(loginData) : null;

        var data = {
            token: loginData.token,
            refreshToken: loginData.refreshToken,
            id : loginData.id
          };
      
        var response = await axiosInstance({
          method: 'post',
          url: POST_REFRESHTOKEN,
          data: data,
        })
      
        isDev ? console.log(response) : null;

        isDev ? console.log(appName) : null;

        if(response){
      
          var payload = response.data;

          isDev ? console.log(payload) : null;

          if(payload.lockoutEnabled == true){
            localStorage.removeItem(appName + "authUser");
            localStorage.removeItem(appName + "authToken");
            throw "User is locked out!";
          }
      
          if(payload.token !== ""){

            var newRefreshToken = payload.refreshToken;
            var newToken = payload.token;
      
            loginData.token = newToken;
            loginData.token = newToken;
            loginData.refreshToken = newRefreshToken;

            isDev ? console.log(newRefreshToken) : null;
            isDev ? console.log(newToken) : null;

            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;

            await localStorageSaveEncrypted(appName + "authToken", payload.token);
            await localStorageSaveEncrypted(appName + "authUser", JSON.stringify(loginData));
            
            return newToken;
          }
        }else{
          isDev ? console.log("REMOVED TOKENS!") : null;
            localStorage.removeItem(appName + "authUser");
            localStorage.removeItem(appName + "authToken");
            window.location.href = "/auth/sign-in";
        }
    };

export default axiosInstance