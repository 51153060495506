import { Navigate, useLocation} from 'react-router-dom';
import { useAuth } from './userContext';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import MainLayout from 'layouts/MainLayout';

const PrivateRoute = () => {
    
    const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
    
    const location = useLocation();

    let { user } = useAuth();
    
    // isAuthenticated = true;
    isDev ? console.log(user) : null;
    
        if(user != null){
            if(user.otpVerified === true){
                return (
                    <MainLayoutProvider>    
                        <MainLayout />
                    </MainLayoutProvider>
                )
            }else{
                return(
                    <Navigate to="auth/otp" replace state={{ from: location }} />
                )
            }
        }else{
            return(
                <Navigate to="auth/sign-in" replace state={{ from: location }} />
            )
        }
    };

export default PrivateRoute;