import { useMemo, useState , ChangeEvent, useEffect, MouseEventHandler} from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import loader1Lottie from '../../assets/img/bg/8.png';

const DataTableSpinner1 = () => {

  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  //const [animationData, setAnimationData] = useState<ArrayBuffer | undefined>(undefined);

  // useEffect(() => {
  //   // Fetch the Lottie file from the public folder
  //   fetch('../../assets/img/lottie/loadingStripes.lottie')
  //     .then(response => {
  //       if (response.ok) return response.text(); // Get the file as text
  //       throw new Error('Failed to load animation data');
  //     })
  //     .then(data => {
  //       setAnimationData(JSON.parse(data)); // Parse the text to JSON
  //     })
  //     .catch(error => console.error('Error loading the animation data:', error));
  // }, []);

    return (
      <div>
        <DotLottieReact
          autoplay
          loop
          data={require('../../assets/img/lottie/dataLoaderStripes.json')}
          style={{ height: 300, width: 500 }}
        >
        </DotLottieReact>
    </div>
    );
  };
  
  export default DataTableSpinner1;
  