import EcomTotalSellsChart from 'components/charts/e-charts/EcomTotalSellsChart';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import { useAppContext } from 'providers/AppProvider';

const TotalSellsChart = () => {
  
  const { t } = useTranslation();
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

  // const {
  //     config: {theme }
  //   } = useAppContext();

  return (
    <>
      <Row className="justify-content-between align-items-center mb-4 g-3">
        <Col xs="auto">
          <h3>{t('GeneralDashBoard_TotalSellsChart_TotalSellsPayments')},</h3>
          <p className="text-body-tertiary lh-sm mb-0">
          {t('GeneralDashBoard_TotalSellsChart_TotalSellsPaymentsSlug')}
          </p>
        </Col>
        <Col xs={8} sm={4}>
          <Form.Select size="sm">
            <option value="mar">aug 1 - 31, 2024</option>
            <option value="apr">sept 1 - 30, 2-024</option>
            <option value="may">nov 1 - 31, 2024</option>
          </Form.Select>
        </Col>
      </Row>
      <EcomTotalSellsChart />
    </>
  );
};

export default TotalSellsChart;
