import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/avatar-rounded.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import NineDotMenu from './NineDotMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import LanguageDropdown from 'components/common/languageDropdownNavbar'

const NavItems = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
          <LanguageDropdown/>
      </Nav.Item>
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>      
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={avatar57} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
