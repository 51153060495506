import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CSSProperties , ChangeEvent, useState, useRef} from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

export interface SearchBoxProps extends FormControlProps {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  formClassName?: string;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
  onValueChanged: (value : string) => void;
}

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

export const SearchBox = ({
  placeholder = 'Search',
  onValueChanged,
  size,
  className,
  inputClassName,
  formClassName,
  style,
  ...rest
}: SearchBoxProps) => {

  const [value, setValue] = useState('');
  const seachInputRef = useRef<HTMLInputElement>(null);

  function handleFocus() {
    seachInputRef.current && seachInputRef.current.select();
  }

  return (
    <div className={classNames('search-box', className)} style={style}>
      <form className={classNames('position-relative', formClassName)}>
        <Form.Control
          type="search"
          onFocus={handleFocus}
          ref={seachInputRef}
          onChange={e => { 
            isDev ? console.log('Onchange', e.target.value) : null;
            setValue(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              
                e.preventDefault();
                isDev ? console.log(value) : null;
                onValueChanged && onValueChanged(value);
                handleFocus();
            } 
          }}
          placeholder={placeholder}
          className={classNames('search-input search', inputClassName)}
          size={size}
          {...rest}
        />
        <FontAwesomeIcon icon={faSearch} className="search-box-icon" />
      </form>
    </div>
  );
};

export default SearchBox;
