import { useMemo, useState , ChangeEvent, useEffect, MouseEventHandler} from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import loader1Lottie from '../../assets/img/bg/8.png';

const DownloadingFileSpinner = () => {

  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
 
    return (
      <div>
        <DotLottieReact
          autoplay
          loop
          data={require('../../assets/img/lottie/downloadingFile.json')}
          style={{ height: 150, width: 150 }}
        >
        </DotLottieReact>
    </div>
    );
  };
  
  export default DownloadingFileSpinner;
  