import { Col, Row } from 'react-bootstrap';
import TotalOrdersCard from './components/totalOrdersCard';
import NewCustomersCard from './components/newCustomersCard';
import CancellationPercentage from './components/cancellationPercentage';
import PayingVsNonPayingCard from './components/payingVsNonPaying';
import EcomLatestReviewsTable from 'components/tables/EcomLatestReviewsTable';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import TotalSells from './components/totalSells';
import { mapMarkerPoints } from 'data/mapMarkerPoints';
import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
import EcomReturningCustomerRate from 'components/modules/e-commerce/dashboard/EcomReturningCustomerRate';
import TotalSellsChart from './components/totalSellsChart';
import TopRegions from './components/topRegions';
import { useTranslation } from "react-i18next";
import { useAppContext } from 'providers/AppProvider';

const GeneralDashBoard = () => {
    
    const { t } = useTranslation();
    const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

    const {
        config: {theme }
      } = useAppContext();

  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={6}>
            <div className="mb-8">
              <h2 className="mb-2">{t('GeneralDashBoard_Welcome')}</h2>
              <h5 className="text-body-tertiary fw-semibold">
              {t('GeneralDashBoard_Welcome_Slug')}
              </h5>
            </div>
            <TotalSells />
            <hr className="bg-body-secondary mb-6 mt-4" />
            <TotalSellsChart />
          </Col>
          <Col xs={12} xxl={6}>
            <Row className="g-3">
              <Col xs={12} md={6}>
                <TotalOrdersCard />
              </Col>
              <Col xs={12} md={6}>
                <NewCustomersCard />
              </Col>
              <Col xs={12} md={6}>
                <CancellationPercentage />
              </Col>
              <Col xs={12} md={6}>
                <PayingVsNonPayingCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EcomLatestReviewsTable />
      </div> */}
      <Row className="gx-6">
        <Col xs={12} xl={6}>
          <TopRegions />
        </Col>
        <Col xs={12} xl={6}>
          <div className="mx-n4 mx-lg-n6 ms-xl-0 h-100">
            <div className="h-100 w-100" style={{ minHeight: 300 }}>
              <EcomTopRegionsMap data={mapMarkerPoints} />
            </div>
          </div>
        </Col>
      </Row>
      {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-6 pb-9 border-top">
        <Row className="g-6">
          <Col xs={12} xl={6}>
            <EcomProjectionVsActual />
          </Col>
          <Col xs={12} xl={6}>
            <EcomReturningCustomerRate />
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default GeneralDashBoard;
