export class RegistrationRequest {
    id!: string;
    firstName!: string;
    lastName!: string;
    companyName!: string;
    phoneNumber!: string;
    countryCode: string = "NL";
    email!: string;
    password!: string;
}

export default RegistrationRequest