import TopRegionsTable from './topRegionsTable';
import React from 'react';
import { useTranslation } from "react-i18next";

const TopRegions = () => {

    const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
    const { t } = useTranslation();

  return (
    <>
      <div className="mb-5 mt-7">
        <h3> {t('GeneralDashBoard_TopRegions_Title')}</h3>
        <p className="text-body-tertiary">
          {' '}
          {t('GeneralDashBoard_TopRegions_MostlyGeneratedRevenue')}
        </p>
      </div>
      <TopRegionsTable />
    </>
  );
};

export default TopRegions;
