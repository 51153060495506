import Button from 'components/base/Button';
import { Col, Form, Row , Toast} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactSelect from 'components/base/ReactSelect';
import checkEmail from 'assets/img/spot-illustrations/emailActivate.png';

const SignUpSuccessForm = ({ layout}  : { layout: 'simple' | 'card' | 'split' }) => {
  
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

  return (
    <>
      <div className="text-center mb-">
        <h3 className="text-body-highlight">Thank you for registering!</h3>
        <p></p><p></p><p></p><p></p>
        <p className="text-body-tertiary">
        <span className="text-primary-darker">Please check your e-mail and use the link to activate and login to your account...</span> </p>
      </div>
      <div><img src={checkEmail} alt="ipayCheckmail" width={'400px'} /></div>
      <div className="text-center">
          <Link
            to={`/auth/sign-in`}
            className="fs-9 fw-bold"
          >
            Go to sign in page (please verify e-mail link first)
          </Link>
        </div>
    </>
  );
};

export default SignUpSuccessForm;
