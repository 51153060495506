export class QueryParameters {
    private static readonly maxPageSize: number = 500;
    public pageSize: number = 10;

    public pageCount: number = 0;
    public pageNumber: number = 1;

    public searchText: string = "";
    public sortColumn: string = "";
    public sortDescending: boolean = true;

    public hasPreviousPage: boolean = false;
    public hasNextPage: boolean = false;

    public includeCount: boolean = true;
    public jsonParams: string = "";
    public filterParams: any = "";
}

export default QueryParameters
