import Button from 'components/base/Button';
import SearchBox from 'components/common/TableSearchBox';
import moment from "moment";
import { faFileExport, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'providers/AppProvider';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import FeatherIcon from 'feather-icons-react';
// import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
// import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import {ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap';
import Badge, { BadgeBg } from 'components/base/Badge';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import { useMemo, useState , ChangeEvent, useEffect, MouseEventHandler} from 'react';
import QueryParameters from 'api/requests/queryParameters';
// import { useEffectOnce } from '@custom-react-hooks/use-effect-once';
import {fetchTransactions , fetchCustomerList, exportTransactions} from './transactionData';
import { TransactionFilterParams } from 'api/requests/TransactionFilterParams';
import LoadingOverlay from 'react-loading-overlay-nextgen';
import { TransactionsPortalDto } from 'api/responses/TransactionsPortalDto';
import { useTranslation } from "react-i18next";
import ReactSelect from 'components/base/ReactSelect';
// import { currencyFormat } from 'helpers/utils';
// import { stat } from 'fs';
// import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { UilArrowRight } from '@iconscout/react-unicons';
import DatePicker from 'components/base/DatePicker';
// import { an } from '@fullcalendar/core/internal-common';
// import { start } from 'repl';
import DataTableSpinner1 from 'components/spinners/DataTableSpinner1';
import DownloadingFileSpinner from 'components/spinners/downloadingFileSpinner';
import PayoutsHeader from './payoutsHeader';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

const Transactions = () => {
  
  const { t } = useTranslation();

  const {
    config: {theme }
  } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [exportingData, setExportingData] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [createdRecords, setCreatedRecords] = useState(0);
  const [pendingRecords, setPendingRecords] = useState(0);
  const [paidRecords, setPaidRecords] = useState(0);
  const [refundedRecords, setRefundedRecords] = useState(0);
  const [cancelledRecords, setCancelledRecords] = useState(0);

  const [totalRecordCount, setTotalRecordCount] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [statusStatus, setStatusStatus] = useState('all');

  const [payoutList, setPayoutList] = useState<{ key: string; value: string; label:string }[]>([]);
  const [transactionList, setTransactionList] = useState([]);
  const [listOfCustomers, setListOfCustomers] = useState<{ key: string; value: string; label:string }[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState([
    // {
    //   key: "NL",
    //   value: "NL",
    //   label:"Netherlands"
    // },
  ]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [loadDataArgs, setLoadDataArgs] = useState<QueryParameters | undefined>(undefined);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
  };

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const getCustomerList = () => {

    fetchCustomerList().then((data) => {
        
      isDev ? console.log(data) : null;
      isDev ? console.log(data.totalItemCount) : null;
      isDev ? console.log(data.succeeded) : null;

      if(data.succeeded == false){
        if(data.errors[0].description == "Customer not found!" || data.errors[0].description == "User locked out!"){
          isDev ? console.log(data.errors[0].description) : null;
          return;
        }
      }
      
      const customers: {key: string; value: string; label:string}[] = [];

      data.data.map((item: any, index: any) => {
          customers.push({
            key: item.id,
            value: item.id,
            label: item.name
          });
      });

      isDev ? console.log('CustomerList', customers) : null;

       setListOfCustomers(customers);
        setPayoutList(customers);

      // data.forEach((value: { id: number; name: string; }) => {
      //     customers.push({
      //       key: value.id,
      //       value: id,
      //       label: value.name
      //     });
      // });

    });
  };

  const exportDataHandler = (e: any) => {

    setExportingData(true);
    setLoading(true);

    isDev ? console.log(e) : null;
    var parms = loadDataArgs || new QueryParameters();
    parms.filterParams.ExportTo = e;

    exportTransactions(parms).then((data) => {
      isDev ? console.log(data) : null;
      setLoading(false);
      setExportingData(false);
    });

  };


  const loadData = (pageIndex : number, searchText? : string, statusFilter? : string,
    newPageSize? : number, startDate? : Date | undefined, endDate? : Date | undefined, selectedCustomerId? : number) => {
   
    var statusId = null;

    isDev ? console.log('FilterStatus', statusFilter) : null;
    isDev ? console.log('statusStatus', statusStatus) : null;

    var sFilter = '';

    if(statusFilter === undefined){
      sFilter = statusStatus;
    }else{
      sFilter = statusFilter;
    }

    isDev ? console.log('sFilter', sFilter) : null;

    setStatusStatus(sFilter);

    if(searchText !== undefined){
      setSearchText(searchText);
    }

    switch (sFilter) {
      case "All":
        statusId = null;
        break;
      case "created":
        statusId = 0;
        break;
      case "pending":
        statusId = 1;
        break;
      case "paid":
        statusId = 2;
        break;
      case "refunded":
        statusId = 3;
        break;
      case "cancelled":
        statusId = 4;
        break; 
    }

    if(!newPageSize){
      newPageSize = 10;
    }

    var arg = new QueryParameters();
      var filters = new TransactionFilterParams();
      filters.StatusId = statusId;
      
      if(selectedCustomerId !== undefined){
        if(selectedCustomerId !== 0){
          filters.SelectedCustomerId = selectedCustomerId;
        }
      }

      if(searchText !== undefined){
        arg.searchText = searchText as string;
      }
      
      if(startDate !== undefined){
        filters.StartDate = startDate;
      }

      if(endDate !== undefined){
        filters.EndDate = endDate;
      }

      arg.pageCount = 0;
      arg.pageNumber = pageIndex;
      arg.pageSize = newPageSize;
      arg.includeCount= true;
      arg.jsonParams = '';
      arg.filterParams = filters;
    
      isDev ? console.log(arg) : null;
      
      setLoadDataArgs(arg);

      isDev ? console.log(loadDataArgs) : null;

      // var results = getTransactions(arg);
      fetchTransactions(arg).then((data) => {
        
        isDev ? console.log(data) : null;
        isDev ? console.log(data.totalItemCount) : null;

        isDev ? console.log(data.succeeded) : null;

        if(data.succeeded == false){
          if(data.errors[0].description == "Customer not found!" || data.errors[0].description == "User locked out!"){
            isDev ? console.log(data.errors[0].description) : null;
            return;
          }
        }

        isDev ? console.log(tabItems) : null;

        setTotalRecordCount(data.totalItemCount);
        setPageCount(data.pageCount);


        if(data.xtraData.value1 === undefined){
          setTotalRecords(0);
          tabItems[0].count =0;
  
          setCreatedRecords(0);
          tabItems[1].count = 0;
  
          setPendingRecords(0);
          tabItems[2].count = 0;
  
          setPaidRecords(0);
          tabItems[3].count =0;
  
          setRefundedRecords(0);
          tabItems[4].count = 0;
  
          setCancelledRecords(0);
          tabItems[5].count = 0;
        }else{
          setTotalRecords(data.xtraData.value1);
          tabItems[0].count =data.xtraData.value1;
  
          setCreatedRecords(data.xtraData.value2);
          tabItems[1].count = data.xtraData.value2;
  
          setPendingRecords(data.xtraData.value3);
          tabItems[2].count = data.xtraData.value3;
  
          setPaidRecords(data.xtraData.value4);
          tabItems[3].count = data.xtraData.value4;
  
          setRefundedRecords(data.xtraData.value5);
          tabItems[4].count = data.xtraData.value5;
  
          setCancelledRecords(data.xtraData.value6);
          tabItems[5].count = data.xtraData.value6;
        }


        isDev ? console.log('TotalRecordCount',  data.totalItemCount) : null;
        isDev ? console.log('PageCount',  data.pageCount) : null;
        isDev ? console.log('TotalRecords',  data.xtraData.value1) : null;
        isDev ? console.log('CreatedRecords',  data.xtraData.value2) : null;
        isDev ? console.log('PendingRecords',  data.xtraData.value3) : null;
        isDev ? console.log('PaidRecords',  data.xtraData.value4) : null;
        isDev ? console.log('RefundedRecords',  data.xtraData.value5) : null;
        isDev ? console.log('CancelledRecords',  data.xtraData.value6) : null;
        
        isDev ? console.log('TransactionList',  data.data) : null;

        //setTransactionList([]);

        setTransactionList(
          data.data as typeof transactionList
        );

        setLoading(false);

        isDev ? console.log('TotalRecordCount',  totalRecordCount) : null;
        isDev ? console.log('PageCount',  pageCount) : null;
        isDev ? console.log('TotalRecords',  totalRecords) : null;
        isDev ? console.log('CreatedRecords',  createdRecords) : null;
        isDev ? console.log('PendingRecords',  pendingRecords) : null;
        isDev ? console.log('PaidRecords',  paidRecords) : null;
        isDev ? console.log('RefundedRecords',  refundedRecords) : null;
        isDev ? console.log('CancelledRecords',  cancelledRecords) : null;

        isDev ? console.log('TransactionList',  transactionList) : null;
      
      });
  };

  // const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   table.setGlobalFilter(e.target.value || undefined);
  // };

  // const onPageChanged = async (pageIndex: number) => {
   
  //   isDev ? console.log(pageIndex) : null;
  //   setLoading(true);
  //   loadData(pageIndex, undefined, statusStatus, undefined, undefined, undefined, undefined);
    
  // };

  const transactionsTablecolumns: ColumnDef<TransactionsPortalDto>[] = [
    {
      accessorKey: 'statusId',
      header: t('TransactionTable_Header_Status'),
      cell: ({ row: { original } }) => {
        const { statusId } = original;
        isDev ? console.log(statusId) : null;
     
        let statusText = '';
  
        switch (statusId) {
          case 0:     //Created payment
          statusText = t('TransactionTable_status_created')
            break;
          case 1:     //Pending payment
            statusText = t('TransactionTable_status_pending_payment') 
            break;
          case 2:     //Paid
          statusText = t('TransactionTable_status_paid') 
            break;
          case 3:     //Refunded
            statusText =t('TransactionTable_status_refunded') 
            break;
          case 4:      //Cancelled
          statusText = t('TransactionTable_status_cancelled') 
            break;
        }
  
        return (
          <Badge
            bg="success"
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                // icon={payment_status.icon}
                size={12.8}
                className="ms-1"
              />
            }
          >
            {statusText}
          </Badge>
        )
      },
      meta: {
        cellProps: { className: 'white-space-nowrap py-1' }
      },
      enableSorting: false
    },
    {
      accessorKey: 'orderId',
      header: t('TransactionTable_Header_Order'),
      cell: ({ row: { original } }) => {
        const { orderId, transactionId } = original;
        isDev ? console.log(orderId) : null;
  
        return (
          <Link to={"https://api.ipay-solutions.com/tr/" + transactionId} target="_blank" rel="noopener noreferrer"  className="text-decoration-none fw-bold fs-9">
            {orderId}
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'white-space-nowrap py-1' }
      },
      enableSorting: false
    },
    {
      accessorKey: 'amount',
      header: t('TransactionTable_Header_Amount'),
      cell: ({ row: { original } }) => {
        const { amount,currency  } = original;
        isDev ? console.log(amount) : null;
        isDev ? console.log(currency) : null;

        return (
            <div>{amount + ' ' + currency}</div>
        );
      },
      meta: {
        headerProps: { style: { width: '6%' }, className: 'text' },
        cellProps: { className: 'text-body-highlight' }
      },
      enableSorting: false
    },
    {
      accessorKey: 'paymentProvider',
      header: t('TransactionTable_Header_PaymentProvider'),
      cell: ({ row: { original } }) => {
        const { paymentProvider } = original;

        return (
            <div>{paymentProvider}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    },
    {
      accessorKey: 'description',
      header: t('TransactionTable_Header_MerchantSite'),
      cell: ({ row: { original } }) => {
        const { description } = original;

        return (
            <div>{description}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    }, 
    {
      accessorKey: 'customer',
      header: t('TransactionTable_Header_Customer'),
      cell: ({ row: { original } }) => {
        const { customer } = original;

        return (
            <div>{customer}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    }, 
    {
      accessorKey: 'orderCountry',
      header: t('TransactionTable_Header_Country'),
      cell: ({ row: { original } }) => {
        const { orderCountry } = original;

        return (
            <div>{orderCountry}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    }, 
    {
      accessorKey: 'carrierName',
      header: t('TransactionTable_Header_Carrier'),
      cell: ({ row: { original } }) => {
        const { carrierName } = original;

        return (
            <div>{carrierName}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    },   
    {
      accessorKey: 'platform',
      header: t('TransactionTable_Header_Platform'),
      cell: ({ row: { original } }) => {
        const { platform } = original;

        return (
            <div>{platform}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    },  
    {
      accessorKey: 'modified',
      header: t('TransactionTable_Header_Modified'),
      cell: ({ row: { original } }) => {
        const { modified } = original;

        return (
          <div>{moment(modified).format("DD-MM-yyyy HH:mm:ss")}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    },  
    
    {
      accessorKey: 'created',
      header: t('TransactionTable_Header_Created'),
      cell: ({ row: { original } }) => {
        const { created } = original;

        return (
            <div>{moment(created).format("DD-MM-yyyy HH:mm:ss")}</div>
        );
      },
      meta: {
        // headerProps: { style: { width: '10%' }, className: 'text-begin' },
        // cellProps: { className: 'text-end fw-semibold text-body-highlight' }
      },
      enableSorting: false
    },  
  ];

  const table = useAdvanceTable({
    data: transactionList,
    columns: transactionsTablecolumns,
    pageSize: 10,
    pagination: true,
    selection: true,
    sortable: true,
    pageCount: pageCount
  });

  const {pagination: { pageSize, pageIndex }} = table.getState();
  
  var ranonce = false;

  useEffect(() => {
    if (!ranonce) {
      ranonce = true

      isDev ? console.log(pageIndex) : null;
      setLoading(true);
      getCustomerList();
      //loadData(pageIndex+1);
      loadData(pageIndex+1, searchText, statusStatus, undefined, startDate, endDate, selectedCustomerId);
  }
  }, [pageIndex]);

  const handleSearchInputChange = (value: string) => {
    isDev ? console.log('handleSearchInputChange',value ) : null;
    setLoading(true);
    loadData(1, value, statusStatus, undefined, startDate, endDate, selectedCustomerId);
   // table.setGlobalFilter(value || undefined);
  };

  const { getColumn, getPrePaginationRowModel } = table;

  const handleStatusTabFilterSelected = (columnId: string, value: string) => {
    const column = getColumn(columnId);

    column?.setFilterValue(value === 'all' ? '' : value);
    
    isDev ? console.log('handleFilterItemClick', value) : null;
    
    setLoading(true);
    setStatusStatus(value);
    loadData(pageIndex+1, searchText, value, undefined, startDate, endDate, selectedCustomerId);

    isDev ? console.log(value) : null;
    isDev ? console.log(setStatusStatus) : null;

  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    // const getDataCount = (label: string) =>
    //   getPrePaginationRowModel().rows.filter(
    //     ({ original: { status } }: any) => status.label === label
    //   ).length;
   
    return [
      {
        label: 'TransactionTable_status_all',
        value: 'all',
        onClick: () => handleStatusTabFilterSelected('status', 'all'),
        count: totalRecords
      },
      {
        label: 'TransactionTable_status_created',
        value: 'created',
        onClick: () => handleStatusTabFilterSelected('status', 'created'),
        count: createdRecords
      },
      {
        label: 'TransactionTable_status_pending_payment',
        value: 'pending',
        onClick: () => handleStatusTabFilterSelected('status', 'pending'),
        count: pendingRecords
      },
      {
        label: 'TransactionTable_status_paid',
        value: 'paid',
        onClick: () => handleStatusTabFilterSelected('status', 'paid'),
        count: paidRecords
      },
      {
        label: 'TransactionTable_status_refunded',
        value: 'refunded',
        onClick: () => handleStatusTabFilterSelected('status', 'refunded'),
        count: refundedRecords
      },
      {
        label: 'TransactionTable_status_cancelled',
        value: 'cancelled',
        onClick: () => handleStatusTabFilterSelected('status', 'cancelled'),
        count: cancelledRecords
      }
    ];
  }, []);

  const onRefreshClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoading(true);
    // setPageIndex(1);
    loadData(pageIndex, searchText, statusStatus, undefined, startDate, endDate, selectedCustomerId);
  };
  
  var progressOverlayBackGroundColor = "204, 204, 204, 0.3";
  var progressOverlayTextColor = "#000000";
  var spinnerColor = 'green';

  if(theme === 'dark'){
    progressOverlayBackGroundColor = "21, 25, 38, 0.5";
    progressOverlayTextColor = '#85A9FF';
    spinnerColor = '#2AF08F';
  }
  
  const customStyles = {
    container: (base: any) => ({
      ...base,
      width: `270px`
    }),
    control: (base: any) => ({
      ...base,
      // border: 0,
      boxShadow: "none",
      fontSize: '12.3px',
      // border: '1px solid gray !important',
    }),
    menu: (base: any) => ({
      ...base,
      width: "270px",
    })
  };

  const handlePayoutSelected = (value : any) => {

    isDev ? console.log(value) : null;

  };

  const handleCustomerSelectionChanged = (value : any) => {

    setSelectedCustomer(value);
    setLoading(true);

    if(value !== null){
        setSelectedCustomerId(value.key);
        loadData(pageIndex+1, searchText, statusStatus, undefined, startDate, endDate, value.key);
    }else{
      loadData(pageIndex+1, searchText, statusStatus, undefined, startDate, endDate, undefined);
      setSelectedCustomerId(0);
    }

    isDev ? console.log(value) : null;
  };

  const handleDateFilter = (startDate : any, endDate : any) => {
    
    isDev ? console.log(startDate) : null;
    isDev ? console.log(endDate) : null;

    setStartDate(startDate);
    setEndDate(endDate);
    
    setLoading(true);
    loadData(pageIndex+1, searchText, statusStatus, undefined, startDate, endDate, selectedCustomerId);
  
  };

   return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">{t('Transactions_Title')}</h2>
        <hr/>
        <PayoutsHeader
          payoutList={payoutList}
          onPayoutChanged={handlePayoutSelected}
        /> 
        {/* <hr className="bg-body-secondary mb-6 mt-4" /> */}
        {/* <hr className="bg-body-secondary mb-6 mt-4" />         */}
        <FilterTab tabItems={tabItems} className="mb-2" />
        <hr/>
        <AdvanceTableProvider {...table}>
          <LoadingOverlay
              active={loading}
              spinner={exportingData === true ? <DownloadingFileSpinner/> : <DataTableSpinner1 />}
              text={t('Transactions_DataListPleaseWait')}
              styles={{
                spinner: (base) => ({
                  ...base,
                  width: '70px',
                  '& svg circle': {
                    stroke: spinnerColor
                  }
                }),
                overlay: (base) => ({
                  ...base,
                  background: 'rgba(' + progressOverlayBackGroundColor + ')',
                  color: progressOverlayTextColor,
                  fontWeight: 'bold',
                  fontSize:'20px',
                  width: '100%'
                })
              }}              
              >
            <div className="mb-4">
              <div className="d-flex flex-wrap gap-3">
                <SearchBox
                  placeholder={t('Transactions_SearchTransactionsPlaceHolder')}
                  onValueChanged={handleSearchInputChange}
                  // onChange={handleSearchInputChange}
                />
                {/* <div className="scrollbar overflow-hidden-y"> */}

                <ButtonGroup className="position-static">
                   <ReactSelect
                    styles={customStyles}
                    id="customers"
                    isClearable
                    value={selectedCustomer}
                    options={listOfCustomers}
                    handleChanged={handleCustomerSelectionChanged}
                    placeholder={t('Transactions_SelectCustomerPlaceHolder')}
                    icon={
                      <UilArrowRight
                        className="react-select-icon text-body-tertiary"
                          size={16}
                        />
                      }
                    />
                  <DatePicker 
                    onDateChanged ={handleDateFilter}
                    style={{width: '240px'}}
                    id = 'dateFilter'
                    options={{
                      mode: "range",
                      // minDate: "today",
                      dateFormat: "d-m-Y",
                    }}
                    placeholder={t('Transactions_DateFilterPlaceHolder')}
                  />            
                </ButtonGroup>

                {/* </div> */}
                <div className="ms-xxl-auto">
                  {/* <Button variant="link" className="text-body me-4 px-0">
                    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                    Export
                  </Button> */}
                    <DropdownButton variant="outline-light" onSelect={exportDataHandler} as={ButtonGroup} title={t('Transactions_ExportDataButton')} id="exportData" style={{marginRight:'15px'}}>
                      <Dropdown.Item eventKey="csv" >{t('Transactions_ExportDataButton_Csv')},</Dropdown.Item>
                      <Dropdown.Item eventKey="excel">{t('Transactions_ExportDataButton_Excel')}</Dropdown.Item>
                    </DropdownButton>
                  <Button variant="link" className="text-body me-4 px-0" onClick={onRefreshClicked} >
                    <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                    {t('Transactions_DataListRefresh')}
                  </Button>
                  {/* <Button variant="primary">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Transaction
                  </Button> */}
                </div>
              </div>
            </div>

            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                <div>
                  <AdvanceTable
                    tableProps={{
                        size: 'sm',
                        className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
                      }}
                    />
                  <AdvanceTableFooter 
                  pagination 
                  totalRecords = {totalRecordCount} 
                  showViewAllBtn={false} />
              </div>
            </div>
          </LoadingOverlay>          
        </AdvanceTableProvider>
      </div>
    </div>
  );
};


export default Transactions;  