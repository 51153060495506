import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChartPie } from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillWave } from '@fortawesome/pro-duotone-svg-icons';
// import FeatherIcon from 'feather-icons-react';

import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

export const routes: RouteItems[] = [
  {
    label: 'Navigation_Section',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: false,
    pages: [
          {
            name: 'Navigation_DashBoard',
            path: '/',
            pathName: 'default-dashboard',
            active: true,
            iconSet: 'font-awesome',
            icon: faChartPie,
          },
          {
            name: 'Navigation_Transactions',
            path: '/transactions',
            pathName: 'transaction',
            iconSet: 'font-awesome',
            icon: faMoneyBillWave,
            active: true
          },
    ]
  },
];

// export const routes: RouteItems[] = [
//   {
//     label: 'dashboard',
//     horizontalNavLabel: 'home',
//     active: true,
//     icon: UilChartPie,
//     labelDisabled: false,
//     pages: [
//           {
//             name: 'general',
//             path: '/',
//             pathName: 'default-dashboard',
//             topNavIcon: 'shopping-cart',
//             icon: 'FeatherIcon',
//             active: true
//           },
//     ]
//   },
//   {
//     label: 'data',
//     horizontalNavLabel: 'home',
//     active: true,
//     icon: UilChartPie,
//     labelDisabled: false,
//     pages: [
//           {
//             name: 'transactions',
//             path: '/',
//             pathName: 'default-dashboard',
//             topNavIcon: 'shopping-cart',
//             icon: 'FeatherIcon',
//             active: true
//           },
//     ]
//   },
// ];


     // {
          //   name: 'CRM',
          //   path: '/dashboard/crm',
          //   pathName: 'crm',
          //   topNavIcon: 'phone',
          //   active: true
          // },
          // {
          //   name: 'Travel Agency',
          //   new: true,
          //   path: '/dashboard/travel-agency',
          //   pathName: 'travel-agency',
          //   topNavIcon: 'phone',
          //   active: true
          // },
          // {
          //   name: 'social-feed',
          //   path: '/apps/social/feed',
          //   pathName: 'social-feed',
          //   topNavIcon: 'share-2',
          //   active: true
          // }