import Badge from 'components/base/Badge';
import EcomTotalOrdersChart from 'components/charts/e-charts/EcomTotalOrdersChart';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const TotalOrdersCard = () => {

    const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
    const { t } = useTranslation();

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">
             {t('GeneralDashBoard_TotalOrdersCard_TotalOrders')}
              <Badge bg="warning" variant="phoenix" pill className="ms-2">
                -6.8%
              </Badge>
            </h5>
            <h6 className="text-body-tertiary"> {t('GeneralDashBoard_TotalOrdersCard_Last7Days')}</h6>
          </div>
          <h4>16,247</h4>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <EcomTotalOrdersChart />
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">{t('GeneralDashBoard_TotalOrdersCard_Completed')}</h6>
            <h6 className="text-body fw-semibold mb-0">52%</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-primary-subtle me-2" />
            <h6 className="text-body fw-semibold flex-1 mb-0">
            {t('GeneralDashBoard_TotalOrdersCard_PendingPayment')}
            </h6>
            <h6 className="text-body fw-semibold mb-0">48%</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TotalOrdersCard;
